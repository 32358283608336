import React from "react"
import Layout from "../components/layout"
// seo import
import { Seo } from "../components/Seo"

const about = () => {
  return (
    <Layout>
      <div className="ui-body-container products">
        <h1 className="body-title product-title">Our Story</h1>
        <div className="spacing-divider--extra-small"></div>
        <div className="ui-body__text-image products">
          <div className="ui-body__text-image--left product-content">
            <p>
              IA is a company I started from just an idea back in 2019 when my
              father asked me to figure out a solution to his problem. He had
              been running sales teams for almost 20 years by that point and
              always struggled with visibility inside sales targets, my task was
              to create visibility where there was none.
            </p>

            <p>
              It took a whole year to develop the first algorithm and create
              real meaningful results, this felt like a lifetime but it paid off
              as now we can offer 90% accuracy on our data. Year 2 was more
              focused on branding and really getting to a place where it wasn’t
              just a heap of tools and code on my MacBook but a heap of tools
              and code hosted in the cloud.
            </p>

            <p>
              Fast forward to the present day we are providing even deeper
              insight not just into sales targets but entire marketing campaigns
              and evaluating vast sums of data. We’ve assembled an amazing team
              devoted to solving even more difficult problems than when I
              started out. We’re utilising technology right on the bleeding edge
              of what’s possible and this culture of innovation will reflect in
              the increasing success of our clients.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default about

export const Head = () => <Seo />
